// packages
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

const PolitikAssets = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "politik-assets" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);
  return (
    <ul>
      {data.allFile.edges.map((file, index) => {
        return (
          <li key={`${index}`}>
            <a href={file.node.publicURL}>{file.node.name}</a>
          </li>
        );
      })}
    </ul>
  );
};

export default PolitikAssets;
